import React, {useContext, useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    MenuItem,
    TextField,
    Select,
    Chip,
    InputLabel,
    FormControl,
    OutlinedInput,
    Slider,
    Typography,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio,
    IconButton,
} from "@mui/material";
import { useTheme } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import Fade from '@mui/material/Fade';
// import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';

import "./SellDevicePage.scss";
import {IsVisibleContext} from "../App";
import Modal from "@mui/material/Modal";
// import {Card} from "@mui/joy";
import Card from '@mui/material/Card';
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import logo from "../assets/img/car_icon_logo_gray.svg";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import manufacturerData from './../assets/json/manufacturer_models.json';
import axios from "axios";


// Mock data
// const carBrands = ["Audi", "BMW", "Mercedes", "Toyota", "Honda", "Tesla", "Ford"];
// const colors = ["Schwarz", "Weiss", "Grau", "Silber", "Blau", "Grün", "Rot", "Gelb", "Braun"];
const colorMap = {
    Schwarz: "black",
    Weiss: "white",
    Grau: "gray",
    Silber: "silver",
    Blau: "blue",
    Grün: "green",
    Rot: "red",
    Gelb: "yellow",
    Braun: "brown",
};

const transmissions = ["Automat", "Schaltgetriebe"];
const fuelTypes = ["Benzin", "Diesel", "Hybrid", "Elektro"];
// const carBrands = ["Audi", "WW", "BMW", "Mercedes", "Toyota", "Ford"];
const carModels = manufacturerData;


const CarPurchaseForm = () => {

    const { isVisibleHeader, setIsVisibleHeader } = useContext(IsVisibleContext);
    const [step, setStep] = useState(() => {
        const savedStep = sessionStorage.getItem("step");
        return savedStep !== null ? parseInt(savedStep, 10) : 0;
    });
    const theme = useTheme();
    const [formData, setFormData] = useState(() => {
        // Încarcă datele salvate din `sessionStorage` la montare
        const savedData = sessionStorage.getItem('formData');
        return savedData ? JSON.parse(savedData) : {
            selectedCars: [],
            priceRange: [0, 100000],
            mileageRange: [0, 300000],
            yearRange: [1900, 2024],
            colors: [],
            transmissions: [],
            fuelTypes: [],
            remarks: "",
        };
    });

    const [carList, setCarList] = useState([]); // List of added cars
    const [currentCar, setCurrentCar] = useState({ brand: "", model: "" }); // Current selection
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false); // Modal visibility
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [responseMessage, setResponseMessage] = useState("");

    const [errorMessage, setErrorMessage] = useState('');

    const stepRefs = useRef([]);

    const handleNext = () => {
        console.log(formData.selectedCars.length)
        if (formData.selectedCars.length === 0) {
            console.log()
            // Show alert modal or error message
            setErrorMessage("Bitte wähle mindestens ein Auto (Marke und Modell), bevor Du fortfährst.");
            setIsAlertModalOpen(true);
        } else {
            setStep((prev) => prev + 1);
        }
    };
    const handlePrev = () => setStep((prev) => Math.max(prev - 1, 0));

    useEffect(() => {
        // Convertim `formData` în string și îl salvăm
        sessionStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]); // Rulează de fiecare dată când `formData` se schimbă

    const handleChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
            ...(field === "brands" && { models: [] }), // Reset models if brands change
        }));
    };

    const [emailData, setEmailData] = useState({
        to: "dariuspulhac@gmail.com",
        subject: "Contact Cars",
        message: "Va transmitem salutari!",
    });
    const handleFormSubmit = async () => {
        if (email === "" || name === "") {
            // Show modal with error message
            setErrorMessage("Bitte füge Deine E-Mail-Adresse hinzu. Diese Angabe benötigen wir, um Dir das Angebot zukommen lassen zu können.");
            setIsAlertModalOpen(true);
        } else {
            sessionStorage.removeItem("formData");
            sessionStorage.removeItem("step");
            try {
                const response = await axios.post("https://wir-suchen-ihr-auto.ch/backend/api/send-email", emailData, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                setResponseMessage(response.data.status || "Email sent successfully!");
                console.log(responseMessage)
            } catch (error) {
                setResponseMessage(error.response?.data.error || "An error occurred while sending the email.");
                console.log(responseMessage)
            }
            // alert(`Form submitted: ${JSON.stringify(formData, null, 2)}`);
        }
    };

    const addCarToFormData = (brand, model) => {
        setFormData((prev) => ({
            ...prev,
            selectedCars: [...prev.selectedCars, { brand, model }],
        }));
    };

    useEffect(() => {
        // Scroll the current step into view when it changes
        sessionStorage.setItem("step", step);
        if (stepRefs.current[step]) {
            stepRefs.current[step].scrollIntoView({
                behavior: 'smooth',
                block: 'center', // Center the element in the viewport
            });
        }
    }, [step]);

    const handleBrandChange = (e) => {
        const newBrand = e.target.value;
        setCurrentCar((prev) => ({
            ...prev,
            brand: newBrand,
            model: "", // Reset model when brand changes
        }));
    };

    const handleModelChange = (e) => {
        setCurrentCar((prev) => ({
            ...prev,
            model: e.target.value,
        }));
    };

    const handleAddCar = () => {
        if (currentCar.brand) {
            addCarToFormData(currentCar.brand, currentCar.model);
            setCarList((prev) => [...prev, currentCar]);
            setCurrentCar({ brand: "", model: "" });
            setIsModalOpen(false);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        // Example regex for a basic phone number (allows digits, spaces, parentheses, dashes)
        const phoneRegex = /^[+]?[0-9\s\-\(\)]{7,15}$/;
        return phoneRegex.test(phone);
    };

    const themeColors = createTheme({
        palette: {
            error: {
                main: '#2f364c', // Example custom error color
            },
            // primary: {
                // main: '#4CAF50', // Example custom primary color
            // },
            // You can customize other colors like secondary, etc.
        },
    });

    return (
        <ThemeProvider theme={themeColors}>
            <div id={"home"}>
                <div style={{marginTop: "100px", marginBottom: "100px"}} className="container">
                    <Fade in={isVisibleHeader} timeout={1000} >
                        <div className={"container"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"d-flex m-auto flex-column justify-content-center align-items-center py-3 py-sm-5"}>
                                        <h1 className="home-page-title font40 extraBold text-center">Wir suchen Ihr neues Auto</h1>
                                        <p className={"text-center mb-2"}>einfach, schnell und kostenlos - wir ersparen Ihnen die mühsame Sucherei</p>
                                        <div className="car car2"><img className={"mirrored"} src={logo} alt={logo} height={"50px"}/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade in={isVisibleHeader} timeout={2000} >
                        <Box sx={{ width: "100%", maxWidth: 800, mx: "auto" }}>
                            {step !==8 && (
                                <div className="brand_model">
                                    <h5 className={"mb-3"}>Marke & Modell</h5>

                                    <Box ref={(el) => (stepRefs.current[0] = el)}>
                                        {/* Add Car Button */}
                                        <Button variant="contained" onClick={() => setIsModalOpen(true)}>
                                            Fahrzeug hinzufügen
                                        </Button>

                                        {/* Modal */}
                                        <Modal
                                            open={isModalOpen}
                                            onClose={() => setIsModalOpen(false)}
                                            closeAfterTransition
                                            BackdropProps={{
                                                timeout: 200,
                                            }}
                                            aria-labelledby="car-selection-modal-title"
                                            aria-describedby="car-selection-modal-description"
                                        >
                                            <Fade in={isModalOpen} timeout={{ enter: 500, exit: 300 }}>
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        p: 5,
                                                        maxWidth: 500,
                                                        width: '95%',
                                                        backgroundColor: 'white',
                                                        borderRadius: 3,
                                                        boxShadow: 24,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        gap: 2,
                                                        // Responsive styles
                                                        [theme.breakpoints.down('sm')]: {
                                                            p: 4, // Adjust padding for small screens (e.g., mobile)
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        id="car-selection-modal-title"
                                                        variant="h5"
                                                        gutterBottom
                                                        sx={{ fontWeight: 600 }}
                                                    >
                                                        Marke & Modell
                                                    </Typography>

                                                    {/* Brand Selector */}
                                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                                        <InputLabel id="car-brand-label">Marke</InputLabel>
                                                        <Select
                                                            id="car-brand-select" // Add id for linking
                                                            labelId="car-brand-label" // Link to InputLabel
                                                            value={currentCar.brand}
                                                            onChange={handleBrandChange}
                                                            label="Marke" // Required for proper label positioning
                                                            sx={{
                                                                borderRadius: 1.5,
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: "#bdbdbd", // Lighter border color
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: "#007bff", // Hover color
                                                                },
                                                            }}
                                                        >
                                                            {Object.keys(carModels).map((brand) => (
                                                                <MenuItem key={brand} value={brand}>
                                                                    {brand}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>


                                                    {/* Model Selector */}
                                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                                        <InputLabel id="car-model-label">Modell</InputLabel>
                                                        <Select
                                                            id="car-model-select" // Add id for linking
                                                            labelId="car-model-label" // Link to InputLabel
                                                            value={currentCar.model}
                                                            onChange={handleModelChange}
                                                            label="Modell" // Required for proper label positioning
                                                            // disabled={!currentCar.brand} // Disable if no brand selected
                                                            sx={{
                                                                borderRadius: 1.5,
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: "#bdbdbd", // Lighter border color
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: "#007bff", // Hover color
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value={"Alle Modelle"}>
                                                                Alle Modelle
                                                            </MenuItem>
                                                            {(carModels[currentCar.brand] || []).map((model) => (
                                                                <MenuItem key={model} value={model}>
                                                                    {model}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>


                                                    {/* Add Button */}
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleAddCar}
                                                    >
                                                        Fahrzeug hinzufügen
                                                    </Button>
                                                </Box>
                                            </Fade>
                                        </Modal>


                                        {/* Cards */}
                                        <div className={"d-flex flex-wrap justify-content-between align-items-center mt-4 mb-3"} style={{marginLeft: "-15px", marginRight: "-15px"}}>
                                            {formData.selectedCars.map((car, index) => (
                                                <div className={"col-12 col-sm-6 px-3 px-sm-3"}>
                                                    <Card key={index} className={"w-100 mb-4"} sx={{
                                                        // mb: 2,
                                                        p: 3,
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        width: "48%",
                                                        border: "2px solid #ccc", // Make the border more visible (you can adjust color and width)
                                                        borderRadius: "8px", // Optional: rounded corners for a smoother look
                                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
                                                        transition: "transform 0.3s, box-shadow 0.3s", // Smooth transition for hover effect
                                                        '&:hover': {
                                                            transform: "scale(1.01)", // Slight scale-up effect on hover
                                                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Increase shadow on hover
                                                        }
                                                    }}>
                                                        <div>
                                                            <div className={"d-flex mb-2"}>
                                                                <p className={"me-1"}><b>Marke: </b></p>
                                                                <p>{car.brand}</p>
                                                            </div>
                                                            <div className={"d-flex"}>
                                                                <p className={"me-1"}><b>Modell: </b></p>
                                                                <p>{car.model}</p>
                                                            </div>
                                                        </div>
                                                        <div className={"d-flex flex-column"}>
                                                            {/* Edit Button */}
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{ mb: 1 }}
                                                                onClick={() => {
                                                                    setCurrentCar(car); // Load current car into the modal
                                                                    setFormData((prev) => ({
                                                                        ...prev,
                                                                        selectedCars: prev.selectedCars.filter((_, i) => i !== index), // Remove from formData
                                                                    }));
                                                                    setIsModalOpen(true); // Open modal for editing
                                                                }}
                                                            >
                                                                Bearbeiten
                                                            </Button>
                                                            {/* Remove Button */}
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                size="small"
                                                                onClick={() =>
                                                                    setFormData((prev) => ({
                                                                        ...prev,
                                                                        selectedCars: prev.selectedCars.filter((_, i) => i !== index), // Remove from formData
                                                                    }))
                                                                }
                                                            >
                                                                Entfernen
                                                            </Button>
                                                        </div>
                                                    </Card>
                                                </div>
                                            ))}
                                        </div>
                                    </Box>
                                </div>
                            )}

                            {/* Step 1: Preis */}
                            {(step >= 1 && step !==8) && (
                            <Fade in={step >= 1} timeout={{ enter: 500, exit: 300 }}>
                                <Box sx={{ py: 5, borderTop: "1px solid #e5e5e5" }} ref={(el) => (stepRefs.current[1] = el)}>
                                    <div className={"w-100"} style={{marginBottom: "20px"}}>
                                        <h5 className={"me-auto d-flex"}>Preis</h5>
                                        <p>von CHF / bis CHF</p>
                                    </div>
                                    {/*<Typography className={"me-auto d-flex"} gutterBottom>Preis (CHF)</Typography>*/}
                                    <div className={"px-3 w-100"}>
                                        <Slider
                                            value={formData.priceRange}
                                            onChange={(e, newValue) => handleChange("priceRange", newValue)}
                                            valueLabelDisplay="auto"
                                            valueLabelFormat={(value) => `€${value.toLocaleString()}`} // Format the value with a dollar sign and commas
                                            min={0}
                                            max={100000}
                                        />

                                    </div>
                                    <div className={'d-flex w-100 gap-4'}>
                                        {/* Min Value Input */}
                                        <TextField
                                            label="Min (CHF)"
                                            type="number"
                                            value={formData.priceRange[0]}
                                            onChange={(e) => {
                                                const newMin = Math.max(0, Number(e.target.value)); // Ensure min value is >= 0
                                                const newMax = Math.max(newMin, formData.priceRange[1]); // Ensure min <= max
                                                handleChange("priceRange", [newMin, newMax]);
                                            }}
                                            sx={{ width: "100%" }}
                                            inputProps={{
                                                min: 0,
                                                style: { height: '16px' } // Adjust height here
                                            }}
                                        />
                                        {/* Max Value Input */}
                                        <TextField
                                            label="Max (CHF)"
                                            type="number"
                                            value={formData.priceRange[1]}
                                            onChange={(e) => {
                                                const newMax = Math.min(100000, Number(e.target.value)); // Ensure max value is <= 100000
                                                const newMin = Math.min(newMax, formData.priceRange[0]); // Ensure min <= max
                                                handleChange("priceRange", [newMin, newMax]);
                                            }}
                                            sx={{ width: "100%" }}
                                            inputProps={{ min: 0, max: 100000, style: { height: '16px' } }}
                                        />
                                    </div>
                                </Box>
                            </Fade>
                            )}

                            {/* Step 2: Kilometerstand */}
                            {(step >= 2 && step !==8) && (
                            <Fade in={step >= 2} timeout={{ enter: 500, exit: 300 }}>
                                <Box sx={{ py: 4, borderTop: "1px solid #e5e5e5" }} ref={(el) => (stepRefs.current[2] = el)}>
                                    <div className={"w-100"} style={{marginBottom: "20px"}}>
                                        <h5 className={"me-auto d-flex"}>Kilometerstand</h5>
                                        <p>von km / bis km</p>
                                    </div>
                                    <div className={"px-3 w-100"}>
                                        <Slider
                                            value={formData.mileageRange}
                                            onChange={(e, newValue) => handleChange("mileageRange", newValue)}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={300000}
                                        />
                                    </div>
                                    <div className={"d-flex w-100 gap-4"}>
                                        {/* Min Value Input */}
                                        <TextField
                                            label="Min (km)"
                                            type="number"
                                            value={formData.mileageRange[0]}
                                            onChange={(e) => {
                                                const newMin = Math.max(0, Number(e.target.value)); // Ensure min value is >= 0
                                                const newMax = Math.max(newMin, formData.mileageRange[1]); // Ensure min <= max
                                                handleChange("mileageRange", [newMin, newMax]);
                                            }}
                                            sx={{ width: "100%" }}
                                            inputProps={{ min: 0, style: { height: '16px' } }}
                                        />
                                        {/* Max Value Input */}
                                        <TextField
                                            label="Max (km)"
                                            type="number"
                                            value={formData.mileageRange[1]}
                                            onChange={(e) => {
                                                const newMax = Math.min(300000, Number(e.target.value)); // Ensure max value is <= 300000
                                                const newMin = Math.min(newMax, formData.mileageRange[0]); // Ensure min <= max
                                                handleChange("mileageRange", [newMin, newMax]);
                                            }}
                                            sx={{ width: "100%" }}
                                            inputProps={{ min: 0, max: 300000, style: { height: '16px' } }}
                                        />
                                    </div>
                                </Box>
                            </Fade>
                            )}


                            {/* Step 3: Jahrgang */}
                            {(step >= 3 && step !==8) && (
                            <Fade in={step >= 3} timeout={{ enter: 500, exit: 300 }}>
                                <Box sx={{ py: 4, borderTop: "1px solid #e5e5e5" }} ref={(el) => (stepRefs.current[3] = el)}>
                                    <div className={"w-100"} style={{marginBottom: "20px"}}>
                                        <h5 className={"me-auto d-flex"}>Jahrgang des Fahrzeuges</h5>
                                    </div>
                                    <div className={"px-3 w-100"}>
                                        <Slider
                                            value={formData.yearRange}
                                            onChange={(e, newValue) => handleChange("yearRange", newValue)}
                                            valueLabelDisplay="auto"
                                            min={1900}
                                            max={2024}
                                        />
                                    </div>
                                    <div className={"d-flex w-100 gap-4"}>
                                        {/* Min Year Input */}
                                        <TextField
                                            label="Min (Jahre)"
                                            type="number"
                                            value={formData.yearRange[0]}
                                            onChange={(e) => {
                                                const newMin = Math.max(1900, Number(e.target.value)); // Ensure min value is >= 1900
                                                const newMax = Math.max(newMin, formData.yearRange[1]); // Ensure min <= max
                                                handleChange("yearRange", [newMin, newMax]);
                                            }}
                                            sx={{ width: "100%" }}
                                            inputProps={{ min: 1900, max: 2024, style: { height: '16px' } }}
                                        />
                                        {/* Max Year Input */}
                                        <TextField
                                            label="Max (Jahre)"
                                            type="number"
                                            value={formData.yearRange[1]}
                                            onChange={(e) => {
                                                const newMax = Math.min(2024, Number(e.target.value)); // Ensure max value is <= 2024
                                                const newMin = Math.min(newMax, formData.yearRange[0]); // Ensure min <= max
                                                handleChange("yearRange", [newMin, newMax]);
                                            }}
                                            sx={{ width: "100%" }}
                                            inputProps={{ min: 1900, max: 2024, style: { height: '16px' } }}
                                        />
                                    </div>
                                </Box>
                            </Fade>
                            )}


                            {/* Step 4: Farbe */}
                            {(step >= 4 && step !==8) && (
                            <Fade in={step >= 4} timeout={{ enter: 500, exit: 300 }}>
                                <Box sx={{ py: 4, borderTop: "1px solid #e5e5e5" }} ref={(el) => (stepRefs.current[4] = el)}>
                                    <div className={"w-100"} style={{marginBottom: "20px"}}>
                                        <h5 className={"me-auto d-flex"}>Farbe</h5>
                                    </div>
                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                                        {Object.keys(colorMap).map((color) => (
                                            <FormControlLabel
                                                key={color}
                                                control={
                                                    <Checkbox
                                                        checked={formData.colors.includes(color)}
                                                        onChange={(e) => {
                                                            const newColors = e.target.checked
                                                                ? [...formData.colors, color]
                                                                : formData.colors.filter((c) => c !== color);
                                                            handleChange("colors", newColors);
                                                        }}
                                                        sx={{
                                                            // color: colorMap[color],
                                                            // "&.Mui-checked": {
                                                            //     color: colorMap[color],
                                                            // },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Box
                                                        sx={{
                                                            // backgroundColor: colorMap[color],
                                                            // color: color === "Weiss" ? "#000" : "#fff", // Black text for white backgrounds
                                                            padding: 0,
                                                            borderRadius: "4px",
                                                            textAlign: "center",
                                                            minWidth: "80px",
                                                            display: "flex",
                                                            gap: "5px",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                border: `1px solid ${color === "Weiss" ? "#000" : colorMap[color]}`,
                                                                borderRadius: "50%",
                                                                width: '25px',
                                                                height: '25px',
                                                                backgroundColor: colorMap[color],
                                                                // color: color === "Weiss" ? "#000" : "#fff", // Black text for white backgrounds
                                                            }}
                                                        />
                                                        <div>{color}</div>
                                                    </Box>
                                                }
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            </Fade>
                            )}


                            {/* Step 5: Transmisie Styled as Multi-Select Buttons */}
                            {(step >= 5 && step !==8) && (
                            <Fade in={step >= 5} timeout={{ enter: 500, exit: 300 }}>
                                <Box sx={{ py: 4, borderTop: "1px solid #e5e5e5" }} ref={(el) => (stepRefs.current[5] = el)}>
                                    <div className={"w-100"} style={{marginBottom: "20px"}}>
                                        <h5 className={"me-auto d-flex"}>Getriebe</h5>
                                    </div>
                                    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                                        {transmissions.map((transmission) => (
                                            <Box
                                                key={transmission}
                                                onClick={() => {
                                                    // Toggle the selection
                                                    const isSelected = formData.transmissions.includes(transmission);
                                                    const newTransmissions = isSelected
                                                        ? formData.transmissions.filter((t) => t !== transmission) // Remove if already selected
                                                        : [...formData.transmissions, transmission]; // Add if not selected
                                                    handleChange("transmissions", newTransmissions);
                                                }}
                                                sx={{
                                                    padding: "8px 16px",
                                                    border: formData.transmissions.includes(transmission) ? "2px solid #2f364c" : "1px solid #ccc",
                                                    borderRadius: "4px",
                                                    cursor: "pointer",
                                                    backgroundColor: formData.transmissions.includes(transmission) ? "#2f364c" : "white",
                                                    color: formData.transmissions.includes(transmission) ? "white" : "black",
                                                    "&:hover": {
                                                        borderColor: "#2f364c",
                                                    },
                                                }}
                                            >
                                                {transmission}
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Fade>
                            )}

                            {/* Step 6: Kraftstoffart */}
                            {(step >= 6 && step !==8) && (
                            <Fade in={step >= 6} timeout={{ enter: 500, exit: 300 }}>
                                <Box sx={{ py: 4, borderTop: "1px solid #e5e5e5" }} ref={(el) => (stepRefs.current[6] = el)}>
                                    <FormControl fullWidth>
                                        <InputLabel id="fuel-types-label">Kraftstoffart</InputLabel>
                                        <Select
                                            labelId="fuel-types-label"
                                            id="fuel-types"
                                            multiple
                                            value={formData.fuelTypes}
                                            onChange={(e) => handleChange("fuelTypes", e.target.value)}
                                            input={<OutlinedInput label="Kraftstoffart" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={value}
                                                            sx={{
                                                                backgroundColor: "#007bff", // Custom background for the chip
                                                                color: "#fff", // White text for contrast
                                                                borderRadius: "16px", // Rounded corners
                                                                '&:hover': {
                                                                    backgroundColor: "#0056b3", // Darker blue on hover
                                                                },
                                                            }}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {fuelTypes.map((fuel) => (
                                                <MenuItem key={fuel} value={fuel}>
                                                    {fuel}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Fade>
                            )}

                            {/* Step 7: Bemerkungen */}
                            {(step >= 7 && step !==8) && (
                            <Fade in={step >= 7} timeout={{ enter: 500, exit: 300 }}>
                                <Box sx={{ py: 4, borderTop: "1px solid #e5e5e5" }} ref={(el) => (stepRefs.current[7] = el)}>
                                    <TextField
                                        fullWidth
                                        label="Bemerkungen (Optional)"
                                        multiline
                                        rows={4}
                                        value={formData.remarks}
                                        onChange={(e) => handleChange("remarks", e.target.value)}
                                    />
                                </Box>
                            </Fade>
                            )}

                            {/* Step 8: */}
                            {step >= 8 && (
                                <Fade in={step >= 8} timeout={{ enter: 500, exit: 300 }}>
                                    <Box className={"col-sm-8 mx-auto"} sx={{ py: 4}} ref={(el) => (stepRefs.current[8] = el)}>
                                        {/* Titlu pentru secțiune */}
                                        <div className={"w-100"} style={{marginBottom: "20px"}}>
                                            <h4 className={"me-auto d-flex mb-5"}>Vervollständige deine Angaben</h4>
                                        </div>
                                        {/* Name Field */}
                                        <TextField
                                            className={"mb-4"}
                                            label="Name"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            onChange={(e) => setName(e.target.value)} // Handle name change
                                            value={name}
                                            sx={{
                                                marginBottom: 2,
                                            }}
                                        />

                                        {/* Email Field */}
                                        <TextField
                                            className={"mb-4"}
                                            label="Email"
                                            type="email"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!validateEmail(email) && email !== ""}
                                            helperText={
                                                !validateEmail(email) && email !== ""
                                                    ? "Bitte geben Sie eine gültige E-Mail-Adresse ein."
                                                    : "Diese Angabe benötigen wir, um Dir das Angebot zukommen lassen zu können."
                                            }
                                            onChange={(e) => setEmail(e.target.value)} // Handle email change
                                            value={email}
                                            sx={{
                                                marginBottom: 2,
                                            }}
                                        />

                                        {/* Optional Phone Number Field */}
                                        <TextField
                                            label="Telefonnummer (optional)"
                                            type="tel"
                                            variant="outlined"
                                            fullWidth
                                            error={phone !== "" && !validatePhone(phone)} // Show error when invalid
                                            helperText={
                                                phone !== "" && !validatePhone(phone)
                                                    ? "Geben Sie eine gültige Telefonnummer ein."
                                                    : ""
                                            }
                                            onChange={(e) => setPhone(e.target.value)} // Handle phone number change
                                            value={phone}
                                            sx={{
                                                marginBottom: 2,
                                            }}
                                        />
                                    </Box>
                                </Fade>
                            )}

                            {/* Buttons */}
                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                                <Button
                                    variant="outlined"
                                    onClick={handlePrev}
                                    disabled={step === 0}
                                >
                                    zurück
                                </Button>
                                {step < 8 ? (
                                    <Button variant="contained" onClick={handleNext}>
                                        Nächster
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        // color="success"
                                        onClick={handleFormSubmit}
                                        disabled={!validateEmail(email)} // Disable if email is invalid
                                    >
                                        Sende die Anfrage
                                    </Button>
                                )}
                            </Box>
                            <Modal
                                open={isAlertModalOpen}
                                onClose={() => setIsAlertModalOpen(false)}
                                closeAfterTransition
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={isAlertModalOpen} timeout={{ enter: 500, exit: 300 }}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%', // Position from top
                                            left: '50%', // Position from left
                                            transform: 'translate(-50%, -50%)', // Center it exactly
                                            p: 4,
                                            maxWidth: 400,
                                            width: '95%',
                                            backgroundColor: 'white',
                                            borderRadius: 3,
                                            boxShadow: 24,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 2,
                                        }}
                                    >
                                        {/* Close Button (X) */}
                                        <IconButton
                                            onClick={() => setIsAlertModalOpen(false)}
                                            sx={{
                                                position: "absolute",
                                                top: 8,
                                                right: 8,
                                                color: "gray",
                                                '&:hover': {
                                                    backgroundColor: "transparent",
                                                },
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <ErrorOutlineIcon sx={{ fontSize: '38px', color: "#7374fd" }} />
                                        {/*<Typography variant="h6" color="error" gutterBottom>*/}
                                        {/*    Error*/}
                                        {/*</Typography>*/}
                                        <Typography className={"text-center"} variant="body1" color="textSecondary" sx={{ marginBottom: 3 }}>
                                            {errorMessage}
                                        </Typography>

                                        <Button variant="contained" onClick={() => setIsAlertModalOpen(false)}>
                                            OK
                                        </Button>
                                    </Box>
                                </Fade>
                            </Modal>
                        </Box>
                    </Fade>
                </div>
                <div className="container mb-5">
                    <div>
                        <h3 className={"mb-5 text-center"}>Wie funktioniert die Suche Ihres neuen Autos?</h3>
                    </div>
                    <div className="card-section pb-4 gap-4">
                        <div className="card">
                            <AdsClickIcon className={"mx-auto mb-3"} sx={{ fontSize: '60px', color: "#7374fd" }} />
                            <p>1. Beantworten Sie einpaar kleine Fragen mit Ihren Wünschen sowie Anforderungen an das neue Auto.</p>
                        </div>
                        <div className="card">
                            <SearchIcon className={"mx-auto mb-3"} sx={{ fontSize: '60px', color: "#7374fd" }} />
                            <p>2. Unser professionelles Team sucht im gesamtschweizerischen Händlernetz nach Ihrem perfekten neuen Auto und unterbreitet Ihnen drei persönlich zugeschnittenen Vorschläge,<br></br> alles unverbindlich und komplett transparent.</p>
                        </div>
                        <div className="card">
                            <ThumbUpOffAltIcon className={"mx-auto mb-3"} sx={{ fontSize: '60px', color: "#7374fd" }} />
                            <p>3. Sie wählen Ihren Favoriten aus und wir organisieren alles von der Probefahrt bis hin zum Kauf</p>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default CarPurchaseForm;
