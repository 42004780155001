import React, {createContext, useEffect, useRef, useState} from "react";
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// Screens
import Landing from "./screens/Landing.jsx";
import { BrowserRouter, Routes, Switch, Route } from "react-router-dom";
import TopNavbar from "./components/Nav/TopNavbar";
import Footer from "./components/Sections/Footer";
import ModalBase from "./components/ModalBase/ModalBase";
import SellDevicePage from "./pages/SellDevicePage";
import logo from "./assets/img/car_icon_logo_gray.svg";

export const IsVisibleContext = createContext(null);
export const refSections = createContext();

const theme = createTheme({
    palette: {
        ochre: {
            main: 'rgb(114 115 253)',
            light: '#29af8a',
            dark: 'rgb(204 167 93)',
            contrastText: '#ffffff',
        },
    },
});

export default function App() {

    const aboutSection = useRef();
    const contactSection = useRef();
    const homeSection = useRef();
    const projectsSection = useRef();
    const simpleTextSection = useRef();
    const [isVisibleHeader, setIsVisibleHeader] = useState(false);
    const [refSectionsList, setRefSectionsList] = useState({
        aboutSection: aboutSection,
        homeSection: homeSection,
        contactSection: contactSection,
        projectsSection: projectsSection,
        simpleTextSection: simpleTextSection,
        sectionToScroll: "",

    });

    // Access value associated with the key
    const routes = [
        {
            path: "*",
            element: <SellDevicePage />,
        },
        {
            path: "/",
            element: <SellDevicePage />,
        },
        // {
        //     path: `${localStorage.getItem("language") ? "/resurse" : "ressourcen"}`,
        //     element: <Resurse />,
        // },
    ]

  return (
    <>
        <ThemeProvider theme={theme}>
          <Helmet>
            {/*<link rel="preconnect" href="https://fonts.googleapis.com" />*/}
            {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />*/}
            {/*<link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />*/}

              {/*<link rel="preconnect" href="https://fonts.googleapis.com"/>*/}
              {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>*/}
              {/*<link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600;700&display=swap" rel="stylesheet"/>*/}

              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
              <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet"/>
          </Helmet>

            <BrowserRouter>
                <IsVisibleContext.Provider value={{isVisibleHeader, setIsVisibleHeader}}>
                    <refSections.Provider value={{refSectionsList, setRefSectionsList}}>
                        <TopNavbar />
                        <Routes>
                            {
                                routes && (
                                    routes.map((route, indexRoute) => {
                                        return <>
                                            {route.path === '/' ? <Route key={indexRoute} path={ route.path } exact element={ route.element } /> : <Route key={indexRoute} path={ route.path } element={ route.element } />}

                                        </>

                                    })
                                )
                            }
                        </Routes>
                        <Footer />
                    </refSections.Provider>
                </IsVisibleContext.Provider>
            </BrowserRouter>
        </ThemeProvider>
    </>
  );
}

